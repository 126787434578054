/** @format */
@font-face {
	font-family: Sabon;
	src: url(./fonts/Sabon_Bold.ttf);
	font-weight: bold;
}

@font-face {
	font-family: Sabon;
	src: url(./fonts/Sabon_Italic.ttf);
	font-weight: italic;
}

@font-face {
	font-family: Sabon;
	src: url(./fonts/Sabon_Roman.ttf);
	font-weight: normal;
}

:root {
	--black: #040505;
	--dark: #252a2f;
	--gray: #595b5e;
	--darkl: #363f45;
	--light: #cfcfc5;
	--yellow-light: #fcf5d4;
	--green: #cde2d5;
	--cyan: #00d3ca;
	--orange: #ff7952;
	--blue: #5e68e6;
	--yellow: #f4d956;
	--pink: #ffd7cb;
	--background: #f7f7f6;
	--stroke: rgba(89, 91, 94, 0.5);
	--str: #444e57;
	--text-dark: var(--dark);
	--text-light: #e4e4e1;
	--accent: var(--blue);
	--theme-solid: var(--blue);
	--theme-wash: #eeeeea;
	--theme-accent: var(--orange);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Sabon';

}

h3 {
	font-size: 1.5em;
	line-height: 1.43;
	letter-spacing: 0.036em;
}

h4 {
	color: var(--background);
	font-size: 18px;
	font-style: italic;
}

p,
li {
	font-weight: 300;
	font-size: 1rem;
	letter-spacing: 0.036em;
	color: var(--gray);
	/* text-align: justify; */
}

p {
	line-height: 1.75em;
}

a {
	color: var(--accent);
	font-style: italic;
	text-decoration: none;
}

a:hover {
	color: var(--gray);
}

.cc {
	font-size: 10px;
}

.App {
	height: 86.1vh;
	width: 100%;
	/* background-image: url(./images/bck.png); */
	background-color: var(--background);
	background-size: cover;
	background-position: center;
}

.logo {
	display: flex;
	width: 100%;
	justify-content: center;
	background-color: var(--background);
	color: var(--darkl);
	padding: 1rem;
	font-style: normal;
}

#root {
	min-height: 100vh;
}

/* NavBar ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.NavBar {
	background-color: var(--background);
	overflow: hidden;
}

.slider ul {
	width: 100%;
	display: flex;
	justify-content: center;
}

.slider ul li {
	margin-block: 1rem;
	margin-inline: 0.5rem;
	height: 2.5vh;
	list-style: none;
	width: 14rem;
	text-align: center;
}

.slider ul li a {
	color: var(--dark);
	text-transform: uppercase;
	font-weight: bold;
	display: flex;
	justify-content: center;
	line-height: 150%;
}

.menu-icon .menu,
.closed {
	display: none;
}

.slider ul li a:hover,
.logo:hover {
	color: var(--accent);
	font-weight: 900;
	transition: 0.4s ease;
}

@media (max-width: 900px) {

	.logo {
		width: 100%;
		font-size: 0.8rem;
	}

	.header {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		background-color: var(--background);
	}

	.NavBar {}

	.slider {
		z-index: 1000;
		position: fixed;
		min-width: 100%;
		height: 100vh;
		bottom: -110%;
		background-color: rgba(0, 0, 0, 0.85);
		transition: 0.5s ease;
	}

	.slider.active {
		bottom: 0;
		transition: 0.5s ease;
	}

	.slider ul {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100vw;
	}

	.slider ul li {
		margin-top: 2.5rem;
		margin-bottom: 2.5rem;
		list-style: none;
		width: 100vw;
	}

	.slider ul li a {
		text-decoration: none;
		color: var(--background);
		display: flex;
		width: 100vw;
		justify-content: center;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.slider ul li a:hover {
		background-color: var(--background);
		color: var(--dark);
	}

	.menu-icon .menu {
		display: block;
		color: var(--dark);
		font-size: 2.5rem;
		cursor: pointer;
		margin-left: 15px;
	}

	.closed {
		display: flex;
		cursor: pointer;
		color: var(--background);
		justify-content: flex-start;
		width: 100%;
	}

	.close {
		margin-top: 15px;
		margin-left: 15px;
	}

	.closed .close {
		font-size: 40px;
	}
}

/* Footer ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.container_Footer {
	background-color: var(--dark);
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.contacts {
	display: flex;
	/* justify-content: space-evenly; */
	font-size: 1em;
}

.icon {
	margin-right: 1rem;
	/* margin-top: 5px; */
	font-size: 24px;
}

.footer_para {
	display: flex;
	align-content: center;
	color: var(--background);
	margin-right: 5vw;
	padding: 15px;
}

/* CV ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.cv_info {
	display: flex;
	flex-direction: row;
	width: 90vw;
	align-items: center;
	align-self: center;
}

.approach {
	/* width: 60vw; */
	display: block;
	margin-top: 7%;
	margin-bottom: 5%;
}

.container_pages {
	padding: 0 2rem;
	display: flex;
	width: 99vw;
	height: 100%;
	min-height: 80vh;
	justify-content: center;
	align-items: center;
}

.container_cv {
	justify-content: center;
	display: flex;
	min-height: 80vh;
}

.container_cv p {
	text-align: justify;

}

.profile_pic {
	padding: 2rem;
	max-height: 70vh;
	max-width: 40vw;
	border-radius: 0;
}

.work_li {
	padding-left: 2rem;
	padding-right: 2rem;
}

.container_pages p,
.work_li li {
	margin-top: 1rem;
}

.desc_text {
	padding: 6.61%;
}

@media (max-width: 1300px) {
	.cv_info {
		flex-direction: column;
		width: 100vw;
	}

	.container_pages {
		height: auto;
	}

	.profile_pic {
		max-width: 90vw;
		max-height: 50vh;
	}

	.approach {
		margin-top: 5%;
	}

	.contacts {
		font-size: 0.7em;
	}
}

/* Exp ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.container_exp {
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
	min-height: 80vh;
}

.exp_list {
	display: flex;
	flex-direction: column;
	width: 80vw;
	margin-top: 50px;
}

.exp_block {
	padding-top: 2rem;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	align-self: flex-start;
}

.exp_cert_block {
	cursor: pointer;
}

.exp_location_img {
	min-width: 150px;
	min-height: 150px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 100%;
	overflow: hidden;
}

.exp_header {
	padding: 0 2rem;
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	word-break: break-all;
}

.exp_header a {}

.exp_toggle_title {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	flex: 1;
}

.toggle_icon {
	font-size: 2em;
	line-height: 1.43;
	letter-spacing: 0.036em;
}

.exp_period {
	font-style: italic;
}

.title_exp {
	display: flex;
	width: 100%;
	justify-content: center;
	padding: 2rem;
	margin-top: 50px;
	text-align: center;
}

.filler-text p {
	text-align: center;
}


.exp_extend {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: -20px 0px;
}

.exp_desc {
	text-align: justify;
	margin: 0px 40px;
	width: 60vw;
}

.exp_cert_container {
	display: flex;
	align-items: center;
	width: auto;
}

.exp_cert {
	border-radius: 20px;
	margin: 30px;
	justify-content: center;
	align-items: center;

}

.exp_cert div {
	display: flex;
	flex: 1;
	min-width: 10vw;
	min-height: 15vh;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: flex-start;
	background-position-x: center;

}

.cert_desc {
	display: flex;
	flex-wrap: wrap;
	padding: 10px 20px;
	width: 300px;
	text-align: center;
}

@media (max-width: 1300px) {

	.exp_block {
		margin: 20px 0;
	}

	.exp_extend {
		display: flex;
		width: 100vw;
		justify-content: center;
		align-items: center;
	}

	/* .exp_desc {
		width: 50%;
	} */

	.exp_cert_container {
		flex-direction: column;
	}

	.exp_cert {
		flex: 1;
		min-width: 30vw;
		max-width: 50vw;
		min-height: 25vh;
		background-size: cover;
		margin-left: 0px;
		margin-right: 0px;
	}

	.exp_extend {
		flex-direction: column;
		width: 100%;
	}
}

@media (max-width: 900px) {
	.exp_cert {
		width: 50vw;
		min-height: 25vh;
		background-size: cover;
		margin-left: 0px;
		margin-right: 0px;
	}

	.exp_block {
		display: flex;
		flex-direction: column;
	}

	.exp_location_img {
		margin-bottom: 1rem;
	}
}

/*Suppliers ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.sustain_brief {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

}

.sustain_filler {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80%;
}

.filler-text-sustain {
	padding: 25px 50px;
	text-align: center;
	display: flex;
	flex-direction: column;
	width: 50vw;
	align-content: center;
	justify-content: center;
	min-width: 30vw;
}

.sustainability_text {
	text-align: left;
}


.filler-photo {
	height: 200px;
	width: 800px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	overflow: hidden;
}

.logo_bar {
	display: flex;
	width: 90vw;
	justify-content: center;
	margin-bottom: 50px;
}

.supplier_logo {
	width: 150px;
	height: 150px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	overflow: hidden;
	margin: 10px;
	display: flex;
	align-self: center;
	justify-self: center;
}


.filler-photo {
	width: 75%;
	height: 75%;
}

@media (max-width: 1300px) {
	.sustain_filler {
		flex-direction: column-reverse;
		align-items: center;
	}

	.filler-text-sustain {
		width: 100%;
	}

}

@media (max-width: 900px) {
	.logo_bar {
		display: grid;
		grid-template-columns: repeat(2, 40vw);
		justify-content: center;
		margin-bottom: 50px;
	}
}

/* Swiper ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.swiper {
	width: 100%;
	height: 100%;
	--swiper-pagination-color: var(--accent);
}

.swiper-slide {
	text-align: center;
	font-size: 18px;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
	padding: 20px;
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center;
}

.swiper-button-next::after,
.swiper-button-prev::after {
	display: none;
}

.swiper-button-next {
	background-image: url(./images/next.png);
}

.swiper-button-prev {
	background-image: url(./images/prev.png);
}

@media (max-width: 1300px) {}

/* Nutrition and Health ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.filler-text {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.healthDesc {
	width: 60vw;
}

.hth_li {
	display: grid;
	grid-template-columns: repeat(2, 35vw);
	grid-template-rows: 65vh;
	margin-top: 50px;
}

.health_block {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
}

.healthCard {
	display: flex;
	justify-content: center;
	margin: 25px;
}

.healthBlock {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.healthSwiperImages {
	display: flex;
	justify-content: center;
	margin: 10px;

}

.hth_header {
	justify-content: center;
	display: flex;
	flex-direction: column;
	margin: 25px;
}

.health_img {
	min-width: 150px;
	min-height: 150px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.wrapper {
	width: 40vw;
	height: 50vh;
	display: inline-block;
	position: relative;
}

.wrapper:after {
	padding-top: 100%;
	display: block;
	content: '';
}

.swiper-img-health {
	display: block;
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
}

.main_wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	max-height: 85%;
}

.recipeGrid {
	display: grid;
	grid-template-columns: repeat(5, 15vw);
	grid-template-rows: repeat(3, auto);
	margin: 5vh 0;
}

.recipeDayBlock {
	border: solid;
}

.recipeDay {
	font-weight: bold;
	font-style: italic;
	font-size: 1.2rem;
	text-align: center;
}

.recipeBlock {
	padding: 40px;
	text-align: center;
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 25vh;
	overflow: hidden;
}

.recipeBlock:hover p,
.recipeActive p {
	color: var(--blue);
	cursor: pointer;
}

.annotateP {
	color: black;
	font-size: 0.85rem;
	line-height: 0.3rem;
	font-style: italic;
	justify-self: flex-start;
}

.recipeDish {
	justify-self: center;
	max-width: 75vw;
}

.recipeDisplay {
	display: flex;
	width: 75vw;
	margin-bottom: 40px;
}

.recipeDisplay span {
	margin: 20px;
	min-width: 30vw;
}

@media (max-width: 1300px) {
	.hth_header {
		margin-bottom: 0;
	}

	.hth_li {
		display: flex;
		flex-direction: column;
	}

	.wrapper {
		width: 100%;
	}

	.healthCard {
		height: auto;
	}

	.main_wrapper {
		max-height: 100%;
	}

	.recipeGrid {
		display: flex;
		flex-direction: column;
		width: 80vw;
	}

	.healthRecipeContainer {
		display: flex;
		flex-direction: column;
		align-items: center;

	}

	.recipeBlock {
		height: auto;
		padding: 10px
	}

	.recipeDisplay {
		flex-direction: column;
	}

	.recipeDisplay span {
		width: 80vw;
	}
}

/* Events ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.menu_list {
	width: 80vw;
}

.menu_block {
	padding-top: 2rem;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	align-self: flex-start;
	cursor: pointer;
	width: 80vw;
}

.menu_cat {
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
}

.menu_cat .swiper-slide {
	align-items: start;
}

.exp_header {
	z-index: 10;
}


@media (max-width: 900px) {
	.menu_block {
		flex-direction: column;
	}
}

.menu_extend {
	display: flex;
}

.menu_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.menuSwiperContainer {
	display: flex;
	justify-content: center;
	margin-bottom: 5vh;
	min-width: 40vw;
	width: 100vw;
}

.EventSwiperContainer {
	display: flex;
	justify-content: center;
	align-content: center;
	justify-items: center;
	align-items: center;
	justify-self: center;
	align-self: center;
	height: 75vh;
	margin-top: 50px;
}

.guestChefs,
.menu_set_container {
	margin: 2rem;
	min-width: 40vw;
	text-align: center;
}

.menu_set_container p {
	line-height: 1rem;
}

.guestChefs {
	margin: 5em;
}

.swiper-img-sustain {
	display: flex;
	flex: 1;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	height: auto;
	object-fit: cover;
	object-position: center;
	align-self: center;
}

@media (max-width: 1300px) {
	.menu_extend {
		flex-direction: column-reverse;
	}

	.menuSwiperContainer {
		margin-top: 2.5rem;
		width: 80vw;
	}

	.swiper-img-sustain {
		padding: 0;
		margin: 0;
		height: auto;
		width: 70vw;
		align-self: center;
		justify-content: center;
	}
}

@media (max-width: 900px) {
	.EventSwiperContainer {
		height: 40vh;
	}
}


.event_img {
	display: flex;
	width: auto;
}

.event_img img {
	display: flex;
	flex: 1;
	min-width: 15vw;
	min-height: 15vh;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: flex-start;
	background-position-x: center;
	margin: 3vh 16vw;
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(calc(-250px*14))
	}
}

.logo_slider {
	height: 120px;
	margin: auto;
	overflow: hidden;
	position: relative;
	width: auto;
	margin: 20px;
}

.logo_slider .slide-track {
	animation: scroll 40s linear infinite;
	display: flex;
	width: calc(250px*14);
	justify-content: center;
	align-items: center;
}

.logo_slider .slide {
	width: 250px;
	margin: 10px 50px;
}

.slide img {
	width: 250px;
	height: 100px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: flex-start;
	background-position-x: center;
}